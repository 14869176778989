import { styled } from '@mui/system'

const shouldForwardProp = (prop) => !['columnsGap', 'columns', 'rowsGap', 'rows', 'items', 'breakpoint'].includes(prop)

const GridRoot = styled('div', { shouldForwardProp })`
	display: grid;
	// use minmax(0px, 1fr) to avoid overflow-x issue with Swiper
	// rif. https://github.com/nolimits4web/swiper/issues/3599#issuecomment-1290897438
	grid-template-columns: repeat(${({ columns }) => columns}, minmax(0px, 1fr));
	column-gap: ${({ columnsGap }) => columnsGap || 8}px;
	row-gap: ${({ rowsGap }) => rowsGap || 8}px;

	${({ theme }) => theme.breakpoints.up('md')} {
		column-gap: ${({ columnsGap }) => columnsGap || 16}px;
		row-gap: ${({ rowsGap }) => rowsGap || 16}px;
	}
`

const GridItem = styled('div', { shouldForwardProp })`
	grid-column-end: span ${({ columns }) => columns || 1};
	grid-row-end: span ${({ rows }) => rows || 1};
`

export { GridRoot, GridItem }
