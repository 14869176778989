import { useConfig } from '@bluheadless/ui-logic/src/providers/config'
import { useCurrency } from '@bluheadless/ui-logic/src/providers/currency'
import { usePageBuilderProducts } from '@bluheadless/ui-logic/src/hooks/product/usePageBuilderProducts'
import { useTrackingsActions } from '@bluheadless/ui-logic/src/hooks/trackings'
import { Box } from '@mui/material'
import { cx } from '@emotion/css'
import round from 'lodash/round'
import AdvancedGrid from '../../../../../../molecules/advanced-grid'
import SkeletonCard from '../../../../../../molecules/skeleton/card/skeleton-card'
import TileConnected from '../../../../../../molecules/tile/connected/tile-connected'
import Carousel from '../../../../../../organisms/carousel'
import { Slide } from '../../../../../slider'
import { useCallback, useEffect, useState } from 'react'
import { usePageContext } from '@bluheadless/ui-logic/src/providers/page/page'
import { CATALOG_DEFAULT_VIEW_MODE } from '@bluheadless/ui-logic/src/constants'
import { ModelView } from '@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/products/products.styled'

const Products = ({
	sx,
	cssProp,
	className,
	appearance,
	productsCorrelation,
	sliderSettings,
	tileVariant,
	widgetContext,
}) => {
	const {
		catalog: {
			products: {
				images: { width: productImageWidth, height: productImageHeight },
			},
			categories: {
				viewMode: { enabled: configViewModeEnabled },
			},
		},
	} = useConfig()

	const { currency } = useCurrency()
	const { productImpressions } = useTrackingsActions()
	const { entityType: pageType, __bhEntityId: pageId } = usePageContext()

	const { products, loading } = usePageBuilderProducts(productsCorrelation)

	const defaultResponsiveProps =
		appearance === 'grid'
			? AdvancedGrid.defaultProps.responsiveProps
			: appearance === 'carousel'
			? sliderSettings.breakpoints ?? Carousel.defaultProps.breakpoints
			: {}

	const skeletonBreakpoints =
		appearance === 'grid'
			? undefined
			: appearance === 'carousel'
			? Object.keys(sliderSettings.breakpoints ?? Carousel.defaultProps.breakpoints).reduce(
					(acc, breakpoint) => ({ ...acc, [breakpoint]: parseInt(breakpoint) }),
					{}
			  )
			: undefined

	const responsiveProps = Object.entries(defaultResponsiveProps)
		.map(([breakpoint, props]) => [
			breakpoint,
			{
				...props,
				columns: round(parseFloat(props.columns ?? props.slidesPerView)),
				columnGap: props.spaceBetween ? `${props.spaceBetween}px` : undefined,
			},
		])
		.reduce((acc, [breakpoint, props]) => {
			return { ...acc, [breakpoint]: props }
		}, {})

	const cardRatio = productImageWidth / productImageHeight

	useEffect(() => {
		if (products?.length) {
			productImpressions({
				products,
				currency,
				pageContext: pageType,
				pageId,
				widgetContext: `${widgetContext}-${appearance}`,
			})
		}
	}, [currency, products, productImpressions, pageType, widgetContext, appearance, pageId])

	const [viewMode, setViewMode] = useState(CATALOG_DEFAULT_VIEW_MODE)
	const onViewModeChange = useCallback((mode) => {
		setViewMode(mode)
	}, [])
	const variant = !configViewModeEnabled
		? tileVariant
		: viewMode === CATALOG_DEFAULT_VIEW_MODE
		? 'look'
		: 'lookAlternative'

	return (
		<Box sx={{ ...sx, width: '100%' }} css={cssProp} className={cx('Magento-PageBuilder-Products', className)}>
			{configViewModeEnabled && <ModelView onClick={onViewModeChange} variant={viewMode} />}
			{appearance === 'grid' && (
				<>
					{loading && products?.length === 0 && (
						<SkeletonCard breakpoints={skeletonBreakpoints} cardRatio={cardRatio} responsiveProps={responsiveProps} />
					)}
					{products?.length > 0 && (
						<AdvancedGrid
							items={products?.map((product, index) => ({
								id: product.id,
								content: (
									<TileConnected
										position={index}
										product={product}
										variant={variant}
										title1Props={{ component: 'h3' }}
										widgetContext={`${widgetContext}-${appearance}`}
										isListing
									/>
								),
							}))}
						/>
					)}
				</>
			)}
			{appearance === 'carousel' && (
				<>
					{loading && products?.length === 0 && (
						<SkeletonCard
							breakpoints={skeletonBreakpoints}
							cardRatio={cardRatio}
							responsiveProps={responsiveProps}
							columnGap={sliderSettings.spaceBetween ? `${sliderSettings.spaceBetween}px` : undefined}
						/>
					)}
					{products?.length > 0 && (
						<Carousel {...sliderSettings}>
							{products?.map((product, index) => (
								<Slide key={product.id}>
									{
										<TileConnected
											position={index}
											product={product}
											variant={variant}
											title1Props={{ component: 'h4' }}
											widgetContext={`${widgetContext}-${appearance}`}
											isListing
										/>
									}
								</Slide>
							))}
						</Carousel>
					)}
				</>
			)}
		</Box>
	)
}

export default Products
